<template>
  <v-card 
    width="100%"
    flat
  >
    <v-row
      no-gutters 
    >
      <v-card-title
        :style="{borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh'}"
      >
        {{ title }}
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{height:'88%'}"
      class="pt-2"
    >
      <v-col>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row
            no-gutters
          >
            <v-col
              sm="2"
              md="2"
              :cols="$vssWidth < 600 ? 2 : 8"
              class="d-flex align-center"
            >
              Motivo:              
            </v-col>
            <v-col 
              sm="2"
              md="2"
              :cols="$vssWidth < 600 ? 3 : 8"
            >
              <v-text-field
                label=""
                value="20"
                disabled
                filled
                reverse
                outlined
                dense
                flat        
                single-line   
                class="pt-5 pl-4" 
              />
            </v-col>
            <v-col 
              sm="6"
              md="5"
              :cols="$vssWidth < 600 ? 7 : 8"
              class="pt-5 pl-2"
            >
              <v-text-field
                label=""
                value=""
                outlined
                dense
                flat        
                single-line
              />
            </v-col>
          </v-row> 
          <v-row
            no-gutters
            :style="{backgournd: 'red'}"
          >
            <v-col
              sm="2"
              md="2"
              :cols="$vssWidth < 600 ? 2 : 8"
              class="pb-5"
            >
              Tipo:
            </v-col>
            <v-col
              sm="8"
              md="7"
              :cols="$vssWidth < 600 ? 8 : 8"
              class="pl-4"
            >
              <v-select
                dense
                outlined
                :items="['Cancelar venda', 'Retirar produto']"
              />
            </v-col>
            <v-col
              sm="2"
              md="2"
              :cols="$vssWidth < 600 ? 2 : 8"
              class="pl-2"
            >
              <template>
                <v-btn
                  icon
                  depressed
                  color="primary"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </template>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
            >
              <v-card-title                  
                class="pt-0"
              >
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Procurar na tabela"
                  single-line
                  hide-details
                />
              </v-card-title>
              <v-data-table
                dense
                :headers="headers"
                :items="items"
                :search="search"
              />
            </v-col>
          </v-row>         
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props:{
    title:{
      type: String,
      required: true,
      default: '',
    },
  },
  data(){
    return {
      valid: false,
      search: '',
      headers: [
        {text:'Cd. Motivo', value:'ce140_cd_mtvo',width: '112px'},
        {text:'Motivo', value:'ce140_ds_mtvo'},
        {text:'Tipo', value:'ce140_tp_mtvo'},
        // {text:'excluir', value:'excluir'},
        
      ],
      items: [
        {ce140_cd_mtvo: 1, ce140_ds_mtvo: 'Desistência da compra', ce140_tp_mtvo: 'Cancelar venda'},
        {ce140_cd_mtvo: 2, ce140_ds_mtvo: 'Cartão não passou', ce140_tp_mtvo: 'Cancelar venda'},
        {ce140_cd_mtvo: 3, ce140_ds_mtvo: 'Produto bipado errado', ce140_tp_mtvo: 'Retirar produto'},
        {ce140_cd_mtvo: 4, ce140_ds_mtvo: 'Desistência da compra', ce140_tp_mtvo: 'Cancelar venda'},
        {ce140_cd_mtvo: 5, ce140_ds_mtvo: 'Cartão não passou', ce140_tp_mtvo: 'Cancelar venda'},
        {ce140_cd_mtvo: 6, ce140_ds_mtvo: 'Produto bipado errado', ce140_tp_mtvo: 'Retirar produto'},
        {ce140_cd_mtvo: 7, ce140_ds_mtvo: 'Desistência da compra', ce140_tp_mtvo: 'Cancelar venda'},
        {ce140_cd_mtvo: 8, ce140_ds_mtvo: 'Cartão não passou', ce140_tp_mtvo: 'Cancelar venda'},
        {ce140_cd_mtvo: 9, ce140_ds_mtvo: 'Produto bipado errado', ce140_tp_mtvo: 'Retirar produto'},
        {ce140_cd_mtvo: 10, ce140_ds_mtvo: 'Desistência da compra', ce140_tp_mtvo: 'Cancelar venda'},
        {ce140_cd_mtvo: 11, ce140_ds_mtvo: 'Cartão não passou', ce140_tp_mtvo: 'Cancelar venda'},
        {ce140_cd_mtvo: 12, ce140_ds_mtvo: 'Produto bipado errado', ce140_tp_mtvo: 'Retirar produto'},
      ],
    }      

  },
  
  watch: {
    
  },
  mounted(){

  },
  methods: {
    
  },
}
</script>